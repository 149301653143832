import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, Input, InputNumber, Select } from "antd";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { Alert } from "antd";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { LoanProduct, TenorProps } from "../../../../../../interfaces/loan";
import { useSelector, useDispatch } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import { updateLoanDetailsAction } from "../../../../../../redux/actions";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";

interface LoanRequestStepTwoProps {
  loanProducts: LoanProduct[];
  isLoadingLoanProducts: boolean;
  handleGoBackAndClear: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  encryptedKey: string;
}

const LoanRequestStepTwo = ({
  loanProducts,
  isLoadingLoanProducts,
  handleGoBackAndClear,
  setCurrentStep,
  error,
  setError,
  encryptedKey,
}: LoanRequestStepTwoProps) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedLoanProductId, setSelectedLoanProductId] = useState<
    number | null
  >(null);
  const [tenorList, setTenorList] = useState<TenorProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customTenor, setCustomTenor] = useState(false);
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const selectedLoanDetails = useSelector(
    (store: ReduxStoreModel) => store.loanDetailsReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepTwo.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleSetSelectedLoan = (id: number) => {
    setSelectedLoanProductId(id);
  };

  const selectedProductTenor = () => {
    const result = loanProducts?.find((product: LoanProduct) => {
      const productId = product.id === selectedLoanProductId;
      return productId ? setTenorList(product.tenors) : null;
    });

    return result;
  };

  useEffect(() => {
    selectedProductTenor();
  }, [selectedLoanProductId]);

  useEffect(() => {
    if (selectedLoanProductId) {
      const chosenProduct = loanProducts?.find(
        (product: LoanProduct) => product.id === selectedLoanProductId,
      );

      dispatch(
        updateLoanDetailsAction({
          id: chosenProduct?.id,
          maximum_tenor: chosenProduct?.maximum_tenor,
          maximum_amount: chosenProduct?.maximum_amount,
          aggregator_id: chosenProduct?.aggregator_id,
          product_name: chosenProduct?.product_name,
          repayment_type: chosenProduct?.repayment_type,
          loan_product_detail_id:chosenProduct?.loan_product_detail_id
        }),
      );
    }
  }, [loanProducts, selectedLoanProductId]);

  const onSubmit = async (values: {
    productId: number;
    loanTenor: string;
    principalAmount: string;
    repaymentFrequency: string;
  }) => {
    setIsLoading(true);

    let principalAmount = Number(
      values.principalAmount.replace(/\$\s?|(,*)/g, "").trim(),
    );
    setError("");

    const payload = {
      productId: Number(selectedLoanDetails?.loan_product_detail_id),
      loanTenor: Number(values.loanTenor),
      principalAmount: principalAmount,
      loan_name: selectedLoanDetails?.product_name,
      loanEffectiveDate: new Date(),
      aggregatorId: borrowerDetails.aggregator_id,
      loanTenorInputType: customTenor ? "Custom" : "Default",
    };
    try {
      const response = await postDataWithKey(
        apiEndpoints.generateLoanDetails,
        payload,
        //@ts-ignore
        encryptedKey,
      );

      response.data.product_name = selectedLoanDetails.product_name;
      response.data.loan_amount = principalAmount;
      response.data.repayment_type =
        response.data.repayment_type ?? selectedLoanDetails?.repayment_type;

      if (response.status) {
        dispatch(
          updateLoanDetailsAction({
            loan_amount: principalAmount,
            loan_name: selectedLoanDetails?.product_name,
            product_id: Number(values.productId),
            loan_tenure: Number(values.loanTenor),
            currency: { code: response.data?.currency.code },
            totalPrincipalDisbursed: response.data?.totalPrincipalDisbursed,
            totalInterestCharged: response.data?.totalInterestCharged,
            loanTermInDays: response.data?.loanTermInDays,
            totalRepaymentExpected: response.data?.totalRepaymentExpected,
            product_name: response.data?.product_name,
            repayment_type: response.data?.repayment_type,
            periods: response.data?.periods,
            loan_effective_date: new Date().toLocaleString(),
            loan_tenor_input_type: customTenor ? "Custom" : "Default",
          }),
        );
        setCurrentStep(1);
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRequestStepTwo },
      });
    } finally {
      form.setFieldsValue({
        principalAmount: "",
        loanTenor: "",
        repaymentFrequency: "",
      });
      setIsLoading(false);
    }
  };

  if (isLoading || isLoadingLoanProducts) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  // const loanAmountInputStyle = {
  //   width: "100%",
  //   height: "55px",
  //   borderRadius: "10px",
  //   border: "1px solid #d9d6e0"

  //   hover:
  // }

  return (
    <>
      {error && (
        <div style={{ paddingBottom: "20px" }}>
          <Alert
            message={error}
            type="warning"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}
      <p className="text-muted">Enter your loan information</p>
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit(values)}
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="productId"
          label="Loan product"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select
            placeholder="Select"
            allowClear
            onChange={(productId) => {
              handleSetSelectedLoan(productId);
            }}
            showSearch
            filterOption={(
              input,
              option: { label: string; value: number } | undefined,
            ) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={
              loanProducts &&
              loanProducts.map((item: LoanProduct) => ({
                label: item.product_name,
                value: item.id,
              }))
            }
          >
            {loanProducts &&
              loanProducts?.length > 0 &&
              loanProducts.map((item: LoanProduct) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.product_name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="principalAmount"
          label={`Loan amount ${
            selectedLoanDetails?.maximum_amount
              ? `(maximum loan amount ${formatCurrency(selectedLoanDetails?.maximum_amount)})`
              : ""
          }`}
          rules={[
            { required: true, message: "Required field" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  (selectedLoanDetails?.maximum_amount &&
                    selectedLoanDetails?.maximum_amount >=
                      Number(value.replace(/\$\s?|(,*)/g, "")))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    `Maximum loan amount for the specific loan product is ${formatCurrency(
                      selectedLoanDetails?.maximum_amount || 0,
                    )}`,
                  ),
                );
              },
            }),
          ]}
        >
          <div>
            <InputNumber
              controls={false}
              size="large"
              formatter={(value) =>
                ` ${`${value}`.replace(/\D/g, "")}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ",",
                )
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </div>
          {/* <input className={styles.loanAmountInputField} /> */}
        </Form.Item>

        {customTenor ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              name="loanTenor"
              label={`Number of Repayments ${
                selectedLoanDetails?.maximum_tenor
                  ? `(maximum number of days is ${selectedLoanDetails?.maximum_tenor})`
                  : ""
              }  `}
              rules={[
                { required: true, message: "Required field" },
                {
                  pattern: /^\d*[.]?\d*$/,
                  message: "Enter numbers from 0 - 9",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      (selectedLoanDetails?.maximum_tenor &&
                        selectedLoanDetails?.maximum_tenor >= Number(value))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `Maximum number of days for this product is ${
                          selectedLoanDetails?.maximum_tenor &&
                          selectedLoanDetails?.maximum_tenor
                        }`,
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Specify tenor in days" />
            </Form.Item>
            <Button
              style={{ placeSelf: "end" }}
              type="primary"
              ghost
              onClick={() => setCustomTenor(false)}
            >
              Pick Tenor
            </Button>
          </div>
        ) : (
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            name="loanTenor"
            label="Loan Tenor"
            rules={[{ required: true, message: "Required field" }]}
          >
            <Select placeholder="Select" allowClear={false}>
              {tenorList &&
                tenorList?.length > 0 &&
                tenorList?.map((item) => (
                  <Select.Option value={item.tenorValue} key={item.tenorValue}>
                    {item.tenor}
                  </Select.Option>
                ))}
              <Select.Option value="">
                <Button onClick={() => setCustomTenor(true)}>
                  Custom Tenor
                </Button>
              </Select.Option>
            </Select>
          </Form.Item>
        )}
        {selectedLoanDetails?.repayment_type && (
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            name="repaymentFrequency"
            initialValue={selectedLoanDetails?.repayment_type}
            label={`Repayment Frequency `}
            rules={[{ required: true, message: "Required field" }]}
          >
            <Input readOnly value={selectedLoanDetails?.repayment_type} />
          </Form.Item>
        )}
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "20%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={handleGoBackAndClear}
            >
              Go Back
            </p>
            <button className="btn btn-primary btn-radius">Next</button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default LoanRequestStepTwo;
